<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseCard class="shadow link-card" :automation-id="getAutomationId('link')">
		<div class="text-center d-flex flex-column h-100">
			<div class="flex-grow-1">
				<FontAwesomeIcon class="icon mb-4" :icon="icon" size="lg" />
				<!-- eslint-disable-next-line -->
				<p class="mb-0 title" v-html="title"></p>
				<p class="pt-2 mb-0 text">{{ text }}</p>
			</div>
			<div>
				<BaseButton
					:label="buttonText"
					class="btn-main"
					:automation-id="getAutomationId('link-card-button')"
					:data-tracking-id="getAutomationId('link-card-button')"
					@click="onClick"
				/>
			</div>
		</div>
	</BaseCard>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component renders a card with a button for a link.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import IdMixin from '@/mixins/id.js';
// @vue/component
@Component({
	name: 'LinkCard',
	components: {
		BaseCard,
		BaseButton
	},
	mixins: [IdMixin],

	props: {
		icon: {
			type: Array,
			default: null,
			required: true
		},
		title: {
			type: String,
			default: null,
			required: true
		},
		text: {
			type: String,
			default: null,
			required: true
		},
		buttonText: {
			type: String,
			default: null,
			required: true
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class LinkCard extends Vue {
	/**
	 * Emit the click event for the parent component to use.
	 */
	onClick(evt) {
		this.$emit('click', evt);
	}

	/**
	 * computed property to get the local to determine what image to display.
	 */
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}
}
</script>

<style lang="scss" scoped>
.btn-main {
	min-width: 109px;
	padding: 0px 30px;
	transition: 100ms;
}
.link-card {
	height: 100%;
	box-shadow: 0 0 1rem rgb(229 229 229 / 100%) !important;
}
.icon {
	font-size: 24px;
}
.icon ::v-deep path {
	color: $icon-grey;
}
.title {
	font-family: $josefin-font-family;
	font-size: 24px;
	color: $input-border-focus-blue;
	line-height: 1.25em;
	letter-spacing: -0.7px;
}
.text {
	font-family: $lato-font-family;
	color: $label-grey;
	font-size: 17px;
}
.link-card:hover {
	& .shadow {
		box-shadow: 0 0 1rem rgb(102 206 255 / 85%) !important;
	}
	& .btn-main {
		background-color: $dark-blue;
	}
	& .btn-outline-secondary {
		color: $white;
	}
	& .title {
		color: $dark-blue;
	}
}
</style>
