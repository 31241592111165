<template>
	<Result
		class="result"
		:automation-id="getAutomationId('digital-registration-locked')"
		:title="$t('success.title')"
		:hide-button="true"
		:content="$t('success.content')"
		:icon-type="'fal'"
		:icon="'exclamation-circle'"
		icon-color="error-color"
	>
		<BaseButton
			:label="$t('button.contactUs')"
			:aria-label="$t('button.contactUs')"
			variant="primary"
			:automation-id="getAutomationId('digital-registration-locked-contactus-button')"
			:data-tracking-id="getAutomationId('digital-registration-locked-contactus-button')"
			type="submit"
			@click="navigateToContactUs"
		/>
	</Result>
</template>

<script>
// This component renders the successful registration screen.
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'DigitalRegistrationLocked',
	components: {
		Result,
		BaseButton
	},
	mixins: [IdMixin]
})
export default class DigitalRegistrationLocked extends Vue {
	//Navigate to custom contact page.
	navigateToContactUs() {
		this.$router.push({ name: Routes.CUSTOM_CONTACT, query: { token: this.$route.params.token } });
	}
}
</script>

<i18n>
{
    "en": {
        "success": {
             "title": "Invalid account information has been entered too many times.",
             "content": "For security purposes, your account is now locked.<br/><br/> To unlock your account, please contact our customer service representatives."
        },
        "button": {
            "contactUs": "Contact Us"
        }
    },
    "fr": {
        "success": {
             "title": "Les renseignements de compte que vous avez entrés ne sont pas valides et vous avez dépassé la limite de tentatives de connexion.",
             "content": "Afin d’assurer votre sécurité, votre compte est bloqué.<br/><br/> Pour débloquer votre compte, communiquez avec nos représentants du Service à la clientèle."
        },
        "button": {
            "contactUs": "Communiquez avec nous"
        }
    }
}
</i18n>
