<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div>
		<BRow>
			<BCol class="mt-4">
				<p class="josefinRegular">{{ $t('content.whatsNext') }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol class="mb-2">
				<p class="latoLight">
					{{ $t('content.textOne') }}
					<a id="appLink" :href="appLink">{{ appName }}</a>
					{{ $t('content.textTwo') }}
					<a id="siteLink" href="javascript:;" @click="navigateToLogin">{{
						$t('link.siteText')
					}}</a>
				</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" lg="4" class="mb-4">
				<LinkCard
					:icon="['fal', 'mobile-alt']"
					:title="$t('linkCardOne.title')"
					:text="$t('linkCardOne.text')"
					:button-text="$t('linkCardOne.buttonText')"
					automation-id="digital-registration-success-app"
					class="mb-3"
					@click="navigateToMobileApp"
				/>
			</BCol>
			<BCol cols="12" lg="4" class="mb-4">
				<LinkCard
					:icon="['fal', 'address-card']"
					:title="$t('linkCardTwo.title')"
					:text="$t('linkCardTwo.text')"
					:button-text="$t('linkCardTwo.buttonText')"
					automation-id="digital-registration-success-card"
					@click="navigateToLogin"
				/>
			</BCol>
			<BCol cols="12" lg="4" class="mb-4">
				<LinkCard
					:icon="['fal', 'life-ring']"
					:title="$t('linkCardThree.title')"
					:text="$t('linkCardThree.text')"
					:button-text="$t('linkCardThree.buttonText')"
					automation-id="digital-registration-success-welcome"
					@click="navigateToWelcomeCenter"
				/>
			</BCol>
		</BRow>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import LinkCard from '@/components/common/card/LinkCard.vue';
import * as Routes from '@/constants/Routes.js';
import IdMixin from '@/mixins/id';
import memberCardInfo from '@/models/MemberCardInfo';

// @vue/component
@Component({
	name: 'DigitalRegistrationSuccess',
	components: {
		BRow,
		BCol,
		LinkCard
	},
	mixins: [IdMixin],
	props: {
		/**
		 * member value
		 */
		value: {
			type: Object,
			default: () => {}
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class DigitalRegistrationSuccess extends Vue {
	client = null;

	get appName() {
		if (this.brand === 'MBC' || this.$router.currentRoute.query.brand === 'MBC') {
			return this.$t('link.appText.medavie');
		} else if (this.brand === 'SBC') {
			return this.$t('link.appText.sask');
		} else if (this.brand === 'OBC' || this.brand === 'QBC' || this.brand === 'GEN') {
			return this.$t('link.appText.generic');
		} else {
			return this.$t('link.appText.medavie');
		}
	}

	get appLink() {
		if (this.brand === 'MBC') {
			return this.$t('link.app.medavie');
		} else if (this.brand === 'SBC') {
			return this.$t('link.app.sask');
		} else if (this.brand === 'OBC') {
			return this.$t('link.app.obc');
		} else if (this.brand === 'QBC') {
			return this.$t('link.app.qbc');
		} else if (this.brand === 'GEN') {
			return this.$t('link.app.generic');
		} else {
			return this.$t('link.app.medavie');
		}
	}

	get brand() {
		if (
			sessionStorage.getItem('brand')?.toUpperCase() === 'MBC' ||
			this.$router.currentRoute.query.brand?.toUpperCase() === 'MBC'
		) {
			return 'MBC';
		} else if (
			sessionStorage.getItem('brand')?.toUpperCase() === 'SBC' ||
			this.$router.currentRoute.query.brand?.toUpperCase() === 'SBC'
		) {
			return 'SBC';
		} else if (
			sessionStorage.getItem('brand')?.toUpperCase() === 'OBC' ||
			this.$router.currentRoute.query.brand?.toUpperCase() === 'OBC'
		) {
			return 'OBC';
		} else if (
			sessionStorage.getItem('brand')?.toUpperCase() === 'QBC' ||
			this.$router.currentRoute.query.brand?.toUpperCase() === 'QBC'
		) {
			return 'QBC';
		} else if (
			sessionStorage.getItem('brand')?.toUpperCase() === 'GEN' ||
			this.$router.currentRoute.query.brand?.toUpperCase() === 'SBC'
		) {
			return 'SBC';
		} else {
			return null;
		}
	}

	created() {
		this.$store.dispatch('successBanner', {
			show: true,
			headerText: this.$t('success.title')
		});
		if (this.$route.params?.token) {
			memberCardInfo.getResourceUrls(this.$route.params.token).then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					var segments = response.data.json_contactUrl.split('/');
					if (segments && segments[3]) {
						this.client = segments[3];
					}
				}
			});
		}
	}

	/**
	 * Have to redisplay success banner when page gets update. If not banner will be removed when user toggles language.
	 */
	updated() {
		this.$store.dispatch('successBanner', {
			show: true,
			headerText: this.$t('success.title')
		});
	}

	navigateToLogin() {
		if (
			this.brand === 'SBC' ||
			this.brand === 'QBC' ||
			this.brand === 'OBC' ||
			this.brand === 'GEN'
		) {
			this.$router.push({
				path: `/${this.$store.state.i18n.locale}`,
				query: { brand: this.brand }
			});
		} else {
			this.$router.push({ name: Routes.LOGIN });
		}
	}

	navigateToMobileApp() {
		if (this.brand === 'SBC') {
			window.location.href = 'https://www.sk.bluecross.ca/workplace-benefits/#group-portal-app';
		} else if (this.brand === 'OBC') {
			window.location.href = 'https://www.medaviebc.ca/en/ontarioplans/members#mobile-section';
		} else if (this.brand === 'QBC') {
			if (this.$store.state.i18n.locale === 'en') {
				window.location.href = `https://www.medaviebc.ca/en/quebecplans/members#mobile-section`;
			} else {
				window.location.href = `https://www.medaviebc.ca/fr/regimesquebec/adherents#mobile-section`;
			}
		} else {
			if (this.$store.state.i18n.locale === 'en') {
				window.location.href = 'https://www.medaviebc.ca/en/members/medavie-blue-cross-mobile-app';
			} else {
				window.location.href =
					'https://www.medaviebc.ca/fr/adherents/application-mobile-de-croix-bleue-medavie';
			}
		}
	}

	navigateToWelcomeCenter() {
		if (this.brand === 'SBC') {
			window.location.href = 'https://www.sk.bluecross.ca/customer-service/new-member-onboarding/';
		} else if (this.brand === 'OBC') {
			window.location.href = 'https://www.medaviebc.ca/en/ontarioplans/members';
		} else if (this.brand === 'QBC') {
			if (this.$store.state.i18n.locale === 'en') {
				window.location.href = `https://www.medaviebc.ca/en/quebecplans/members`;
			} else {
				window.location.href = `https://cbmedavie.ca/fr/regimesquebec/adherents`;
			}
		} else {
			if (this.client) {
				if (this.$store.state.i18n.locale === 'en') {
					window.location.href = `https://www.medaviebc.ca/en/members/welcome?client=${this.client}`;
				} else {
					window.location.href = `https://www.medaviebc.ca/fr/adherents/bienvenue?client=${this.client}`;
				}
			} else {
				if (this.$store.state.i18n.locale === 'en') {
					window.location.href = 'https://www.medaviebc.ca/en/members/welcome';
				} else {
					window.location.href = 'https://www.medaviebc.ca/fr/adherents/bienvenue';
				}
			}
		}
	}
}
</script>
<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<style lang="scss" scoped>
a {
	font-weight: 400;
}
.josefinRegular {
	font-family: $josefin-font-family;
	font-weight: $font-regular;
	font-size: $header-main-font-size;
	color: $label-grey;
	margin-bottom: 10px;
}
.latoLight {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 300;
}
* ::v-deep .secondaryTitle {
	font-family: $josefin-font-family;
	font-size: 22px;
	padding-bottom: 0;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
  "en": {
    "content": {
      "whatsNext": "What’s next?",
      "textOne": "You can now log into our",
      "textTwo": "or our"
    },
    "link": {
      "siteText": "Member Services Site.",
      "appText": {
			"medavie": "Medavie Blue Cross mobile app",
			"sask": "Saskatchewan Blue Cross Mobile app",
			"generic": "Blue Cross Mobile app"
		},
      "app":{
			"medavie": "https://www.medaviebc.ca/en/members/medavie-mobile",
			"sask": "https://www.sk.bluecross.ca/workplace-benefits/#group-portal-app ",
			"generic": "https://www.medaviebc.ca/en/members/medavie-mobile",
			"obc": "https://www.medaviebc.ca/en/ontarioplans/members#mobile-section",
			"qbc": "https://www.medaviebc.ca/en/quebecplans/members#mobile-section"
		} 
    },
    "linkCardOne": {
      "title": "Download our app",
      "text": "Easy access to your account and member card from your phone.",
      "buttonText": "Get started"
    },
    "linkCardTwo": {
      "title": "Access your member card",
      "text": "Download and share it with your dependants to use your benefits.",
      "buttonText": "Log in"
    },
    "linkCardThree": {
      "title": "Explore our <span class='d-inline d-lg-block'>Welcome Centre</span>",
      "text": "Learn about your member perks, using our services and tools, and more.",
      "buttonText": "Go explore"
    },
    "success": {
      "title": "You’re all set!"
    }
  },
  "fr": {
    "content": {
      "whatsNext": "Et maintenant?",
      "textOne": "Vous pouvez maintenant l’utiliser pour ouvrir une session sur",
      "textTwo": "ou notre "
    },
    "link": {
      	"siteText": "site Web des adhérents.",
	  	"appText": {
			"medavie": "l’application Medavie Mobile",
			"sask": "l'application mobile de Saskatchewan Blue Cross",
			"generic": "l’application mobile de Croix Bleue."
		},
      	"app":{
			"medavie": "https://www.medaviebc.ca/fr/adherents/medavie-mobile",
			"sask": "https://www.sk.bluecross.ca/workplace-benefits/#group-portal-app ",
			"generic": "https://www.medaviebc.ca/fr/adherents/medavie-mobile",
			"obc": "https://www.medaviebc.ca/en/ontarioplans/members#mobile-section",
			"qbc": "https://www.medaviebc.ca/fr/regimesquebec/adherents#mobile-section"
		} 
    },
    "linkCardOne": {
      "title": "Téléchargez notre application ",
      "text": "Accédez facilement à votre compte et à votre carte d’identification sur votre téléphone.",
      "buttonText": "Commencer"
    },
    "linkCardTwo": {
      "title": "Accédez à votre carte d’identification",
      "text": "Téléchargez-la et envoyez-la à vos personnes à charge afin d’utiliser vos garanties.",
      "buttonText": "Ouvrir une session"
    },
    "linkCardThree": {
      "title": "Explorez notre section <span class='d-inline d-lg-block'>d’accueil des nouveaux adhérents</span>",
      "text": "Apprenez-en plus sur les avantages réservés aux adhérents, l’utilisation de nos services et outils, et bien plus encore.",
      "buttonText": "Explorer"
    },
    "success": {
      "title": "Vous êtes prêt!"
    }
  }
}
</i18n>
