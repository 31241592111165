<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BRow class="justify-content-center">
		<BCol md="10" lg="7" xl="6">
			<FormHeader
				class="header"
				:title="title"
				:secondary-title="$t('subtitle')"
				:automation-id="'digital-verification-header'"
			/>
			<BaseCard
				:img-src="require('@/assets/digital-registration-verification.jpg')"
				img-alt=""
				img-top
				class="card"
				img-height="200"
				body-class="no-top-bottom-pad"
				:automation-id="'digital-verification-body'"
			>
				<BRow class="my-3 my-md-4">
					<BCol>
						<h2>{{ $t('content.setup') }}</h2>
						<p class="main-content">{{ $t('content.accountAccess') }}</p>
						<ul class="list">
							<li class="main-content">{{ $t('list.one') }}</li>
							<li class="main-content">{{ $t('list.two') }}</li>
							<li class="main-content">{{ $t('list.three') }}</li>
						</ul>
						<p class="pb-2 main-content">{{ $t('content.getStarted') }}</p>
						<BaseForm
							ref="digital-verification-form"
							:data-tracking-id="'digital-verification-form'"
							:automation-id="'digital-verification-form'"
							@keydown.enter="submit"
							@submit="onSubmit"
						>
							<BirthDate
								:value="member.dob"
								class="mb-2 input"
								:automation-id="'digital-verification'"
								@input="updateDateOfBirth"
							/>

							<PostalCode
								:value="member.postalCode"
								class="mb-2 postal-code-input"
								:label="$t('label.postalCode')"
								:placeholder="'0 0 0'"
								mask="XXX"
								:max-length="3"
								:required-error-msg="$t('error.requiredPostal')"
								:length-error-msg="$t('error.requiredPostal')"
								:automation-id="'digital-verification'"
								@blur="updatePostalCode"
							/>

							<BaseButton
								class="mt-4"
								variant="primary"
								:label="$t('button.continue')"
								pill
								is-right
								type="submit"
								:automation-id="'digital-verification-submit'"
								data-tracking-id="registration-form-submit-button"
							></BaseButton>
						</BaseForm>
					</BCol>
				</BRow>
			</BaseCard>
			<ErrorModal
				:data-test-automation-id="getAutomationId('digital-verification-modal')"
				:modal-show="modalShow"
				:error-title="$t('modal.title')"
				:error-message="$t('modal.text')"
				@close="toggleModal"
			>
				<BaseButton
					class="mt-2"
					variant="primary"
					:label="$t('button.ok')"
					pill
					is-right
					type="submit"
					:automation-id="getAutomationId('digital-verification-ok')"
					data-tracking-id="registration-modal-ok-button"
					@click="toggleModal"
				></BaseButton>
			</ErrorModal>
		</BCol>
	</BRow>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';
import BaseForm from '@/components/common/base/BaseForm';
import BirthDate from '@/components/common/BirthDate';
import PostalCode from '@/components/common/PostalCode.vue';
import FormHeader from '@/components/common/form/FormHeader';
import BaseCard from '@/components/common/card/BaseCard';
import ErrorModal from '@/components/common/ErrorModal';
import * as Routes from '@/constants/Routes.js';
import Authentication from '@/models/Authentication.js';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'DigitalRegistrationVerification',
	components: {
		BRow,
		BCol,
		FormHeader,
		BaseCard,
		BirthDate,
		PostalCode,
		BaseButton,
		BaseForm,
		ErrorModal
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class DigitalRegistrationVerification extends Vue {
	member = {
		dob: null,
		postalCode: null
	};
	modalShow = false;

	get title() {
		if (
			sessionStorage.getItem('brand') === 'QBC' ||
			sessionStorage.getItem('brand') === 'OBC' ||
			sessionStorage.getItem('brand') === 'GEN'
		) {
			return this.$t('titles.generic');
		} else if (sessionStorage.getItem('brand') === 'SBC') {
			return this.$t('titles.sask');
		} else if (sessionStorage.getItem('brand') === 'MBC') {
			return this.$t('titles.medavie');
		} else {
			return this.$t('titles.medavie');
		}
	}

	//Update the local values with the values the member inputs.
	updatePostalCode(value) {
		this.member.postalCode = value;
	}

	updateDateOfBirth(value) {
		this.member.dob = value;
	}

	//Toggle the error modal.
	toggleModal() {
		this.modalShow = !this.modalShow;
	}

	/**
	 * Handle on click event. Submit the members data to the API to see if it is valid.
	 */
	onSubmit() {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;

		Authentication.validateDigitalRegistrationMember(
			this.$route.params.token,
			this.member.dob,
			this.member.postalCode
		)
			.then((response) => {
				if (response && response.status === 200) {
					if (response.data.emailVerified) {
						localStorage.setItem('mss_hasNoPassword', true);
						localStorage.setItem('mss_member_dob', this.member.dob);
						this.$router.push({
							name: Routes.DIGITAL_PASSWORD,
							params: { token: this.$route.params.token, dob: this.member.dob }
						});
					} else if (response.data.emailLocked) {
						this.$router.push({
							name: Routes.DIGITAL_LOCKED,
							params: { token: this.$route.params.token }
						});
					} else {
						this.toggleModal();
					}
				} else {
					this.$router.push({
						name: Routes.DIGITAL_ERROR,
						params: { token: this.$route.params.token }
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}
}
</script>

<style lang="scss" scoped>
h2 {
	font-size: 24px;
	line-height: normal;
}
.verify-border {
	border-right: none;
	border-bottom: 1px solid #dee2e6;
	@media (min-width: 768px) {
		border-right: 1px solid #dee2e6;
		border-bottom: none;
	}
}
.icon {
	font-size: 2em;
}
.icon ::v-deep path {
	color: $border-orange;
}
.sub-header {
	font-family: $josefin-font-family;
	font-weight: $font-regular;
	font-size: 1.3125em;
}
.main-content {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 300;
}
.card ::v-deep img {
	object-position: top;
	@include media-breakpoint-down(xs) {
		height: 120px;
	}
}
</style>

<i18n>
    {
        "en": {
            "titles": {
				"medavie": "Welcome to Medavie Blue Cross!",
				"sask": "Welcome to Saskatchewan Blue Cross",
				"generic": "Welcome to Blue Cross"
			},
			"subtitle": "We’re happy to have you as a member.",
            "content": {
                "setup": "Let’s set up your online account so you can start using your benefits.",
                "accountAccess": "Use your online account to access:",
				"getStarted": "Enter the info below to get started."
            },
			"list": {
				"one": "your digital member card",
				"two": "info about your plan",
				"three": "self-serve tools"
			},
			"label": {
				"postalCode": "First 3 characters of your postal code/zip code"
			},
            "button": {
                "continue": "Continue",
				"ok": "Ok"
            },
			"modal": {
				"title": "The information entered doesn’t match what we have in our records.",
				"text": "Please check that you’ve entered the information correctly and try again."
			},
            "error": {
				"requiredPostal": "Please enter the first three characters of your postal code."
            }
        },
        "fr": {
			"titles": {
				"medavie": "Croix Bleue Medavie vous souhaite la bienvenue!",
				"sask": "Saskatchewan Blue Cross vous souhaite la bienvenue",
				"generic": "Croix Bleue vous souhaite la bienvenue"
			},
			"subtitle": "Nous sommes heureux de vous compter parmi nos adhérents!",
            "content": {
                "setup": "Configurons votre compte en ligne pour que vous puissiez utiliser vos garanties.",
                "accountAccess": " Utilisez votre compte en ligne pour accéder:",
				"getStarted": "Entrez les renseignements ci-dessous pour commencer."
            },
			"list": {
				"one": "À votre carte d’identification ",
				"two": "Aux renseignements sur votre régime",
				"three": "Aux outils en libre-service"
			},
			"label": {
				"postalCode": "Les trois premiers caractères de votre code postal/code ZIP"
			},
            "button": {
				"continue": "Continuer",
				"ok": "OK"
            },
			"modal": { 
				"title": "L'information que vous avez entrée est différente de celle dans nos dossiers.",
				"text": "Veuillez vérifier si vous avez entré votre information correctement."
			},
            "error": {
				"requiredPostal": "Entrez votre code d’accès pour continuer."
            }
        }
    }
</i18n>
