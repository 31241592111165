<template>
	<BaseMaskedInput
		v-bind="$attrs"
		:value="maskedValue"
		:label="labelComp"
		:max-length="maxLength"
		:mask="mask"
		:validation-rules="{ required: true, length: maxLength }"
		:validation-messages="{ required: requiredErrorMsgComp, length: lengthErrorMsgComp }"
		:automation-id="getAutomationId('postalcode')"
		@input="onInput"
		@blur="onBlur"
	/>
</template>

<script>
/**
 * This component is used to render a postal code input.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseMaskedInput from '@/components/common/base/BaseMaskedInput.vue';
import { required, length } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('length', length);
// @vue/component
@Component({
	name: 'PostalCode',
	components: {
		BaseMaskedInput
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		/**
		 * value is the value that will be used by
		 * this component to populate the input fields.
		 */
		value: {
			type: String,
			default: null
		},
		/**
		 * This prop is used to determine what the mask of the input field will be.
		 */
		mask: {
			default: 'S#S#S#',
			type: String
		},
		/**
		 * Prop used to set the label, if none is set the default one will be used.
		 */
		label: {
			type: String,
			default: null
		},
		/**
		 * this prop will set the max length of the input field.
		 * This is mainly used for the digital policy registration flow, where we verify against first 3 digits of postal code.
		 */
		maxLength: {
			default: 6,
			type: Number
		},
		/**
		 * This prop determines what the error message
		 * displayed will be when the postal code is required.
		 */
		requiredErrorMsg: {
			type: String,
			default: null
		},
		/**
		 * This prop determines what the error message
		 * displayed will be when the length entered is incorrect.
		 */
		lengthErrorMsg: {
			type: String,
			default: null
		},
		/**
		 * Test automation id is used by the test automation Id
		 * suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class PostalCode extends Vue {
	savedMaskedValue = null;

	/**
	 * These computed props check if an error message was passed in,
	 * if it wasn't then it used a default error message.
	 */
	get labelComp() {
		return this.label ? this.label : this.$t('label.postalCode');
	}
	get requiredErrorMsgComp() {
		return this.requiredErrorMsg ? this.requiredErrorMsg : this.$t('error.required');
	}
	get lengthErrorMsgComp() {
		return this.lengthErrorMsg ? this.lengthErrorMsg : this.$t('error.length');
	}

	/**
	 * Returns the mask value.
	 */
	get maskedValue() {
		this.savedMaskedValue = this.value;
		return this.value;
	}

	/**
	 * Handle blur event.
	 */
	onBlur() {
		const postalCode = this.savedMaskedValue;
		this.$emit('blur', postalCode);
	}

	/**
	 * Handle input event
	 * @param maskedValue The masked value.
	 */
	onInput(maskedValue) {
		this.savedMaskedValue = maskedValue;
	}
}
</script>

<i18n>
{
    "en": {
	"label": {
        "postalCode": "Postal code"
	},
	"error": {
		"required": "Please provide your postal code.",
		"length": "Postal code is limited to 6 characters."
	}
   },
  "fr": {
   "label": {
        "postalCode": "Code Postal"
	},
	"error": {
		"required": "Veuillez indiquer votre code postal.",
		"length": "Le code postal est limité à 6 caractères."
	}
  }
}
</i18n>
