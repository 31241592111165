<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BRow class="justify-content-center">
		<BCol md="10" lg="7" xl="6">
			<FormHeader :title="$t('title')" :automation-id="'premium-payments'" />
			<BaseCard
				:img-src="require('@/assets/digital-registration-password.jpg')"
				img-alt=""
				img-top
				img-height="200"
				:automation-id="'registration-dialog'"
			>
				<BaseAlert
					v-if="APIError"
					variant="danger"
					:icon="['fal', 'exclamation-triangle']"
					class="error-body"
				>
					{{ APIError }}
				</BaseAlert>
				<h2 class="sub-header">{{ $t('content.textOne') }} {{ appName }}</h2>
				<p class="content-italic">
					{{ $t('content.textTwo') }}
				</p>

				<PasswordRequirements />

				<BaseForm
					ref="digital-verification-form"
					data-tracking-id="digital-password-form"
					automation-id="digital-password-form"
					@keydown.enter="submit"
					@submit="onSubmit"
				>
					<BRow>
						<BCol sm="8">
							<PasswordInput
								:value="member.password"
								:vid="'register_password'"
								:label="$t('label.password')"
								require-complexity
								automation-id="digital-password"
								@input="updatePassword"
							/>
							<ConfirmInput
								:value="member.confirmPassword"
								:label="$t('label.confirm')"
								:field="$t('label.password')"
								type="password"
								:confirmation="member.password"
								:confirmation-error="$t('error.passwordMatching')"
								password
								automation-id="digital-password"
								@input="updateConfirm"
							/>
						</BCol>
					</BRow>
					<BaseButton
						variant="primary"
						:label="$t('label.button')"
						pill
						is-right
						type="submit"
						:automation-id="'digital-password-submit'"
						data-tracking-id="registration-form-submit-button"
					></BaseButton>
				</BaseForm>
			</BaseCard>
		</BCol>
	</BRow>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseButton from '@/components/common/base/BaseButton';
import BaseForm from '@/components/common/base/BaseForm';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import FormHeader from '@/components/common/form/FormHeader';
import BaseCard from '@/components/common/card/BaseCard';
import PasswordRequirements from '@/components/common/PasswordRequirements';
import PasswordInput from '@/components/common/PasswordInput';
import ConfirmInput from '@/components/common/ConfirmInput';
import { BRow, BCol } from 'bootstrap-vue';
import * as Routes from '@/constants/Routes.js';
import Authentication from '@/models/Authentication.js';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'DigitalRegistrationPassword',
	components: {
		BRow,
		BCol,
		FormHeader,
		BaseCard,
		PasswordRequirements,
		PasswordInput,
		ConfirmInput,
		BaseButton,
		BaseAlert,
		BaseForm
	},
	mixins: [IdMixin],
	props: {
		/**
		 * member value
		 */
		value: {
			type: Object,
			default: () => {}
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class DigitalRegistrationPassword extends Vue {
	member = {
		password: null,
		confirmPassword: null
	};

	get appName() {
		if (
			sessionStorage.getItem('brand') === 'QBC' ||
			sessionStorage.getItem('brand') === 'OBC' ||
			sessionStorage.getItem('brand') === 'GEN'
		) {
			return this.$t('content.appText.generic');
		} else if (sessionStorage.getItem('brand') === 'SBC') {
			return this.$t('content.appText.sask');
		} else if (sessionStorage.getItem('brand') === 'MBC') {
			return this.$t('content.appText.medavie');
		} else {
			return this.$t('content.appText.medavie');
		}
	}

	APIErrorEn = null;
	APIErrorFr = null;

	get APIError() {
		return this.$store.state.i18n.locale === 'en' ? this.APIErrorEn : this.APIErrorFr;
	}

	updatePassword(password) {
		this.member.password = password;
	}

	updateConfirm(confirm) {
		this.member.confirmPassword = confirm;
	}

	onSubmit() {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		// reset the API error just in case it is set.
		this.APIError = null;
		Authentication.registerDigitalWelcome(
			this.$route.params.token,
			this.$route.params.dob,
			this.member.password,
			this.$store.state.i18n.locale
		)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					localStorage.removeItem('mss_hasNoPassword');
					localStorage.removeItem('mss_member_dob');
					this.$router.push({
						name: Routes.DIGITAL_SUCCESS,
						params: { token: this.$route.params.token }
					});
				} else if (
					response?.status === 401 &&
					response?.data?.message &&
					response.data.message !== 'INVALID'
				) {
					this.APIErrorEn = response.data.messageEn;
					this.APIErrorFr = response.data.messageFr;
					this.$store.dispatch('updateLoading', false);
				} else {
					this.$router.push({
						name: Routes.DIGITAL_ERROR,
						params: { token: this.$route.params.token }
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}
}
</script>
<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<style lang="scss" scoped>
h2 {
	font-size: 21px;
	line-height: 1.6rem;
	font-family: $josefin-font-family;
}
.content-italic {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 300;
	font-style: italic;
}
.card ::v-deep img {
	object-position: top;
	@include media-breakpoint-down(xs) {
		height: 120px;
	}
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
    {
        "en": {
            "title": "Create your password",
            "content": {
                "textOne": "You’ll use this password to log into our Member Services Site as well as our ",
				"appText": {
					"medavie": "Medavie Mobile app.",
					"sask": "Saskatchewan Blue Cross Mobile app.",
					"generic": "Blue Cross Mobile app."
				},
                "textTwo": "(Your username is the email address we used to send you the link to this site.)"
            },
            "label": {
                "password": "Password",
                "confirm": "Confirm password",
                "button": "Create your password"
            },
            "error": {
				"passwordMatching":  "The passwords don't match. Please enter the new password in both text boxes."
            }
        },
        "fr": {
            "title": "Créer votre mot de passe",
            "content": {
                "textOne": "Vous utiliserez ce mot de passe pour ouvrir une session sur le site Web des adhérents et notre ",
                "textTwo": "(Votre nom d’utilisateur est le courriel auquel nous avons envoyé le lien pour ce site.)",
				"appText": {
					"medavie": "application Medavie Mobile.",
					"sask": "application mobile de Saskatchewan Blue Cross",
					"generic": "application mobile de Croix Bleue."
				}
            },
            "label": {
                "password": "Mot de passe",
                "confirm": "Confirmer le mot de passe",
                "button": "Créer votre mot de passe"
            },
            "error": {
				"passwordMatching":  "Les mots de passe ne correspondent pas. Veuillez entrer le nouveau mot de passe dans les deux boîtes de texte."
            }
        }
    }
</i18n>
