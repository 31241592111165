<template>
	<Result
		class="result"
		:automation-id="getAutomationId('digital-registration-error')"
		:title="$t('success.title')"
		:hide-button="true"
		:content="$t('success.content')"
		:icon-type="'fal'"
		:icon="'exclamation-circle'"
		icon-color="error-color"
	>
		<BaseButton
			class="button-contact"
			:label="$t('button.contactUs')"
			:aria-label="$t('button.contactUs')"
			variant="primary"
			:automation-id="getAutomationId('digital-registration-error-contactus-button')"
			:data-tracking-id="getAutomationId('digital-registration-error-contactus-button')"
			type="submit"
			@click="navigateToContactUs"
		/>
	</Result>
</template>

<script>
// This component renders the successful registration screen.
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'DigitalRegistrationError',
	components: {
		Result,
		BaseButton
	},
	mixins: [IdMixin]
})
export default class DigitalRegistrationError extends Vue {
	//Navigate to custom contact page.
	navigateToContactUs() {
		this.$router.push({ name: Routes.CUSTOM_CONTACT, query: { token: this.$route.params.token } });
	}
}
</script>

<i18n>
{
    "en": {
        "success": {
             "title": "We seem to have encountered an issue.",
             "content": "Please double-check that you’ve entered the correct and complete URL, as provided in your welcome email.<br/><br/> If you feel you’re seeing this message in error, please contact us for more information."
        },
        "button": {
            "contactUs": "Contact Us"
        }
    },
    "fr": {
        "success": {
             "title": "Il semble que nous éprouvions un problème.",
             "content": "Veuillez vous assurer d’avoir entré l’URL exacte et complète, comme fournie dans votre courriel d’accueil.<br/><br/>  Si vous croyez qu’il s’agit d’une erreur, veuillez communiquer avec nous pour obtenir de l’aide."
        },
        "button": {
            "contactUs": "Communiquez avec nous"
        }
    }
}
</i18n>
